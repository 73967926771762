import React, {ChangeEvent, KeyboardEvent, useState} from "react";
import {Pen} from "react-bootstrap-icons";
import classNames from 'classnames';
import {useSettings} from "./SettingsContext";
import {useFormatters} from "./components/formatters";
import {PriceItem} from "./types";

interface Props {
  item: PriceItem;
  editing: boolean;
  onChange: (item: PriceItem, price: number) => void;
  onNextRow?: (item: any) => void;
  onPrevRow?: (item: any) => void;
  onEditClicked?: (item: any) => void;
  onExcludedChanged?: (ean: string, excluded: boolean) => void;
  onCancelEdit?: () => void;
  hasEdits?:boolean;
}

interface State {
  description: string;
  category: string;
  description2: string;
  ean: string;
  defaultPrice: number;
  price: number;
  children: any[];
  initialPrice: number;
  editedPrice: number|null;
  pendingPrice: number|null;
  expandChildren: boolean;
  isExcluded: boolean;
  
}

export const PriceRow = (props: Props) => {
  const {item, editing, hasEdits, onChange, onNextRow, onPrevRow, onEditClicked, onExcludedChanged, onCancelEdit} = props;
  const {settings} = useSettings();
  const formatters = useFormatters(settings);

  const [state, setState] = useState<State>({
    ...item,
    initialPrice: item.price,
    editedPrice: item.pendingPrice ?? '',
    expandChildren: false
  }, [item]);

  const onKeyUp = (e:KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape' && onCancelEdit)
      onCancelEdit();

    if (e.key !== 'Tab')
      return;

    if (e.shiftKey && e.key === 'Tab' && onPrevRow) {
      onPrevRow(item)
    } else if (e.key === 'Tab' && onNextRow) {
      onNextRow(item)
    }

    if (state.editedPrice === state.initialPrice)
      return;

    if (state.editedPrice === state.pendingPrice)
      return;

    if (state.editedPrice === null)
      return;

    onChange(item, state.editedPrice!);
  }
  const priceChanged = (e:ChangeEvent<HTMLInputElement>) => {
    const newPrice = e.target.value;
    setState({
      ...state,
      editedPrice:  Number(newPrice)
    });
  }

  const editClicked = () => {
    if (onEditClicked)
      onEditClicked(item);
  };
  const expandClicked = () => {
    setState({...state, expandChildren: !state.expandChildren});
  };

  const getTagFromCodes = (codes:any) => {

    if (codes.some(c => c.code.toUpperCase().startsWith('UP'))) {
      return 'Uppgradering'
    }

    if (codes.some(c => c.code.toUpperCase().startsWith('DI'))) {
      return 'Uppgradering'
    }

    if (codes.some(c => c.code.toUpperCase().startsWith('FUN'))) {
      return 'Funbox uppgradering';
    }

    if (codes.some(c => c.code.toUpperCase().startsWith('FOOD'))) {
      return "Foodora";
    }

    return "Artikel";
  }

  const itemExcludedChanged = (e) => {
    const excluded = !e.target.checked;
    setState({...state, isExcluded: excluded})
    if (onExcludedChanged)
      onExcludedChanged(item.ean, excluded);
  }
  const cssClasses = classNames({
    "price-row": true,
    "excluded": state.isExcluded,
    "active": editing,
    "has-edits": hasEdits
  });

  return (
    <li className={cssClasses}>
      <div className={'excluded-cb'}>
        <input className={"form-check-input"} type={"checkbox"}
               checked={!state.isExcluded}
               onChange={itemExcludedChanged}
               tabIndex={-1}
        />
      </div>
      <div className={"description"}>
        <span className={"label"}>Namn</span>
        <span className={"value"}>{state.description}</span>
      </div>
      <div className={"category"}>
        <span className={"label"}>Kategori</span>
        <span className={"value"}>{state.category}</span>
      </div>
      <div className={"type"} onClick={expandClicked}>
        <span className={"label"}>Artikelkoder</span>
        <span className={"value"}>{getTagFromCodes(state.children)}</span>

        {state.expandChildren && (
          <ul className={"group-children"}>
            {state.children.map((child) => {
                return (<li key={child.code}>
                  <span>{child.code}</span>
                  <span>{child.description}</span>
                </li>)
              }
            )}
          </ul>
        )}
      </div>
      <div className={"description2"}>
        <span className={"label"}>Beskrivning</span>
        <span className={"value"}>{state.description2}</span>
      </div>
      <div className={"codes"}>
        <span className={"label"}>Artikelkoder</span>
        <span className={"value"}>{state.children.map(c => c.code)?.join(", ")}</span>
      </div>
      <div className={"ean-code"}>
        <span className={"label"}>EAN</span>
        <span className={"value"}>{state.ean}</span>
      </div>
      <div className={"default-price"}>
        <span className={"label"}>Grundpris</span>
        <span className={"value"}>{formatters.currency.format(state.defaultPrice)}</span>
      </div>
      <div className={"new-price"} onClick={editClicked}>
        <span className={"label"}>lokalt pris</span>
        <span className={"value"}>
          {state.price !== null && (
            <>{formatters.currency.format(state.price)}</>
          )}
        </span>
      </div>
      <div className={"new-price"}>
        <span className={"label"}>Nytt pris</span>
        {editing && (
          <input type={"number"} step={"0.5"}
                 value={state.editedPrice}
                 onChange={priceChanged}
                 onKeyDown={onKeyUp}
          />
        )}
        {!editing && state.editedPrice && (
          <span>
            {formatters.currency.format(state.editedPrice)}
          </span>
        )}
      </div>
      <div className={'edit'}>
        <button tabIndex={-1} className={"btn-edit"} onClick={editClicked}>
          <Pen/>
        </button>
      </div>

    </li>

  )
};
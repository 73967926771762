import React, {useEffect, useState} from "react";
import {Shop} from "../types";
import Loading from "../../Loading";
import {useTranslation} from "react-i18next";
import ShopStatus from "./ShopStatus";

interface Props {
    shop: Shop
}

const StatusRow = (props: Props) => {
    const {t} = useTranslation();
    const {shop} = props;
    return (
        <li className={"shop"}>
            <span className={"shop-name"}>{shop.description}</span>
            <ShopStatus shop={shop}/>
        </li>
    );
}

const ShopStatuses = () => {
    const [loading, setLoading] = useState(false);
    const [shops, setShops] = useState<Shop[]>([]);
    const {t} = useTranslation();
    useEffect(() => {
        setLoading(true);
        fetch('api/pick-n-pay/shops')
            .then(r => r.json())
            .then(data => {
                setShops(data);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (<Loading visible={true}/>)
    }

    return (
        <div className={"widget shop-status-widget "}>
            <h2>{t('pick-n-pay.shop-status-widget.widget-header')}</h2>
            <div className="shop-status-table">
                <ul className={"shops"}>
                    {shops.map(shop => <StatusRow key={shop.id} shop={shop}/>)}
                    {shops.length === 0 && (<li>{t('pick-n-pay.shop-status-widget.no-shops')}</li>)}
                </ul>
            </div>
        </div>
    );
}

export default ShopStatuses;
import React from "react";
//@ts-ignore
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useSettings} from "../../../SettingsContext";
import {useFormatters} from "../../formatters";
import Loading from "../../Loading";
import CustomerDetails from "../customers/CustomerDetails";
import {DateTime} from "luxon";
import {TransactionHeader, TransactionItem} from "../types";
//@ts-ignore
import {ProfitCenter} from "../../../types";
import OrderShopDetails from "./OrderShopDetails";
import OrderItemRow from "./OrderItemRow";
import LabelValue from "../LabelValue";

interface Params {
  orderId: string;
}

const OrderDetails = () => {
  const {orderId} = useParams<Params>();
  const {t} = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = React.useState<TransactionHeader | null>(null);
  const [customer, setCustomer] = React.useState<any | null>(null);
  const [shop, setShop] = React.useState<ProfitCenter | null>(null);
  const {settings} = useSettings();
  const formatters = useFormatters(settings)

  React.useEffect(() => {
    setLoading(true);

    const promises = [
      fetch(`api/pick-n-pay/orders/${orderId}`)
        .then(response => response.json())
        .then(orderData => {
          setOrder(orderData);
          if (orderData.customerCode) {
            fetch(`api/pick-n-pay/customers/${orderData.customerCode}`)
              .then(response => response.json())
              .then(customerData => {
                setCustomer(customerData);
              });
          }
          fetch(`api/pick-n-pay/shops/${orderData.profitCenterCode}`)
            .then(response => response.json())
            .then(shopData => {
              setShop(shopData);
            });
        }),
    ];

    Promise.all(promises).then(() => {
      setLoading(false);
    });

  }, [orderId]);


  if (loading) {
    return (<Loading visible={true}/>);
  }

  return (
    <div className={"pick-n-pay order-details-page"}>
      <h1>{t('pick-n-pay.orders.details.header')}</h1>
      <div className={"order-details-container"}>
        <div className={"order-header"}>
          <h2>{t('pick-n-pay.orders.details.information')}</h2>

          <LabelValue label={t('pick-n-pay.orders.details.id')} value={order?.guid ?? ''}/>
          <LabelValue label={t('pick-n-pay.orders.details.receiptId')} value={order?.transactionHeaderId?.toString() ?? ''}/>
          <LabelValue label={t('pick-n-pay.orders.details.orderNr')} value={order?.orderNr?.toString() ?? ''}/>
          <LabelValue label={t('pick-n-pay.orders.details.created')}
                      value={(order && DateTime.fromISO(order.creationTime, {zone: 'UTC'}).toLocal().toFormat('yyyy-MM-dd hh:mm')) ?? ''}/>
          <LabelValue label={t('pick-n-pay.orders.details.status')} value={order?.status ?? ''}/>
          <LabelValue label={t('pick-n-pay.orders.details.orderType')} value={order?.orderType ?? ''}/>
          <LabelValue label={t('pick-n-pay.orders.details.amount')} value={(order && formatters.currency.format(order.total)) ?? ''}/>
          <LabelValue label={t('pick-n-pay.orders.details.deliverySpot')} value={(order && order.deliverySpot?.name) ?? ''}/>
        </div>

        {customer && (
          <CustomerDetails customer={customer} showOrders={false} showActions={false} showAccountStatus={false}/>
        )}
        {shop && (
          <OrderShopDetails shop={shop}/>
        )}
      </div>
      <h2>{t('pick-n-pay.orders.details.items.header')}</h2>
      <div className={"order-contents search-result-container"}>
        <div className={"search-result-header"}>
          <span>{t('pick-n-pay.orders.details.items.article')}</span>
          <span>{t('pick-n-pay.orders.details.items.quantity')}</span>
          <span>{t('pick-n-pay.orders.details.items.price')}</span>
        </div>
        <ol className={"order-items search-result"}>
          {order?.transactionItems.map((item: TransactionItem, index: number) => (
            <OrderItemRow item={item} key={index}/>
          ))}
          <li className={"total-sum"}>
            <span>{t('pick-n-pay.orders.details.items.total')}</span>
            <span>{formatters.currency.format(order?.total ?? 0)}</span>
          </li>
        </ol>
      </div>
      <h2>{t('pick-n-pay.orders.details.payments.header')}</h2>
      <div className={"order-payments"}>
        <LabelValue label={t('pick-n-pay.orders.details.providerPaymentId')} value={order?.paymentTransactionId ?? ''}/>
        <LabelValue label={t('pick-n-pay.orders.details.providerType')} value={order?.paymentProviderType ?? ''}/>

        <h3>{t('pick-n-pay.orders.details.payments.winposMethod')}</h3>
        <ol className={"winpos-payment-methods"}>
          {order?.payments.map((payment: any, index: number) => (
            <li className={"payment-method"} key={index}>
              <span className={"payment-method-name"}>{payment.description}</span>
              <span className={"amount"}>{formatters.currency.format(payment.amount)}</span>
            </li>
          ))}
        </ol>
      </div>

    </div>
  )
}

export default OrderDetails;

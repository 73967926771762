import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {StripeProvider, SwedBankPayProvider} from "../types";
import ProviderUsage from "./ProviderUsage";

const StripeItem  = (props:any) => {
  const {t} = useTranslation();
  const [editMode, setEditMode] = useState(props.editMode ?? false);
  const [provider, setProvider] = useState<StripeProvider>(props.provider);

  const onSave = ()=>{
    props.onSave && props.onSave(provider);
    setEditMode(false);
  }

  const onDelete = ()=>{
    props.onDelete && props.onDelete(provider);
  }

  const onCancel = ()=>{
    setProvider(props.provider);
    setEditMode(false);
  }
  return (
    <div className={"provider stripe"}>
      <div className={"provider-header"}>
        <h3>{provider.name} ({provider.type})</h3>
        <ProviderUsage usage={provider.usage ?? []}/>
        {!editMode && (
          <>
            <button className={"btn btn-edit"} onClick={() => setEditMode(!editMode)}>{t(`pick-n-pay.payment-providers.editButton`)}</button>
          </>)}
      </div>

      {editMode && (
        <div className={"provider-settings"}>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.payment-providers.name.label`)}</label>
            <input className={"form-control"} type={"text"} value={provider.name ?? ""} onChange={(e) => setProvider({...provider, name: e.target.value})}/>
            <p className={"form-info"}>{t(`pick-n-pay.payment-providers.name.description`)}</p>
          </div>

          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.payment-providers.stripe.publicSecret.label`)}</label>
            <input className={"form-control"} type={"text"} value={provider.publicSecret ?? ""} onChange={(e) => setProvider({...provider, publicSecret: e.target.value})}/>
            <p className={"form-info"}>{t(`pick-n-pay.payment-providers.stripe.publicSecret.description`)}</p>
          </div>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.payment-providers.stripe.privateSecret.label`)}</label>
            <input className={"form-control"} type={"text"} value={provider.privateSecret ?? ""} onChange={(e) => setProvider({...provider, privateSecret: e.target.value})}/>
            <p className={"form-info"}>{t(`pick-n-pay.payment-providers.stripe.privateSecret.description`)}</p>
          </div>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.payment-providers.stripe.webHookSecret.label`)}</label>
            <input className={"form-control"} type={"text"} value={provider.webHookSecret ?? ""} onChange={(e) => setProvider({...provider, webHookSecret: e.target.value})}/>
            <p className={"form-info"}>{t(`pick-n-pay.payment-providers.stripe.webHookSecret.description`)}</p>
          </div>


          <div className={"btn-group"}>
            <button className={"btn btn-save"} onClick={onSave}>{t(`pick-n-pay.payment-providers.addProvider.saveButton`)}</button>
            {!props.createMode && (<>
                <button className={"btn btn-cancel"} onClick={onCancel}>{t(`pick-n-pay.payment-providers.addProvider.cancelButton`)}</button>
                <button className={"btn btn-delete"} onClick={onDelete}>{t(`pick-n-pay.payment-providers.addProvider.deleteButton`)}</button>
              </>
            )}
          </div>
        </div>
      )}

    </div>
  )
};
export default StripeItem;
import React, {useEffect, useRef, useState} from "react";
import {DndProvider, DropOptions, NodeModel, Tree} from "@minoru/react-dnd-treeview";
import {HTML5Backend, NativeTypes} from "react-dnd-html5-backend";
import {ArticleMenuItemComponent, ContainerMenuItemComponent, SeparatorMenuItemComponent} from "./MenuItemComponents";
import {ArticleMenuItem, ContainerMenuItem, Menu, MenuItem, MenuItemTypes, SeparatorMenuItem} from "./Menus";
import {PlaceholderRenderParams, RenderParams, TreeMethods} from "@minoru/react-dnd-treeview/dist/types";
import {ArticleResult} from "../types";
import ContainerModal from "./ContainerModal";
import {usePnpLocales} from "../hooks/usePnpLocales";
import SeparatorModal from "./SeparatorModal";
import ButtonConfirm from "../settings/ButtonConfirm";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

interface Props {
  menu: Menu
  onSave?: (menu: Menu) => void
  onDelete?: (menu: Menu) => void
  onItemsChanged?: (items: MenuItem[]) => void
}

const mapToNodeModel = (items: MenuItem[], parent: number = 0): NodeModel<MenuItem>[] => {
  const result: NodeModel<MenuItem>[] = []
  
  items.forEach(item => {
    const node = {
      id: item.itemId,
      text: item.name,
      parent: item.parentId,
      droppable: false,
      data: item,
    }

    result.push(node);
    if (item.type === MenuItemTypes.Container) {
      node.droppable = true;
    }
  });

  return result;
}

const MenuBuilder = (props:Props) => {
  const [menu, setMenu] = useState<Menu>(props.menu);
  const [tree, setTree] = useState<NodeModel<MenuItem>|null>( menu ? mapToNodeModel(menu.items):null);
  const [isContainerModalOpen, setIsContainerModalOpen] = useState(false);
  const [isSeparatorModalOpen, setIsSeparatorModalOpen] = useState(false);
  
  const [containerEditItem, setContainerEditItem] = useState<ContainerMenuItem | null>(null);
  const [separatorEditItem, setSeparatorEditItem] = useState<SeparatorMenuItem | null>(null);
  
  const {t} = useTranslation();
  const ref = useRef<TreeMethods|null>(null);

  useEffect(()=>{
    //initial emit of items in the menu, this to communicate the items to the search component
    emitItemsChanged();
  },[props.menu]);
  
  const {locales} = usePnpLocales();
  const isNew = ()=>{
    return menu?.id === 0;
  }
  
  const mapToDomainModel = (tree: NodeModel<MenuItem>[]):MenuItem[] => {
    const result: MenuItem[] = []
    tree.forEach(node => {
      const item = node.data;
      item.itemId = node.id;
      item.parentId = node.parent;
      item.itemOrder = tree.indexOf(node);
      item["$type"] = item.type;
      
      result.push(item);
    });

    return result;
  }

  const getLastId = (treeData:NodeModel<MenuItem>[]) => {
    const reversedArray = [...treeData].sort((a, b) => {
      if (a.id < b.id) {
        return 1;
      } else if (a.id > b.id) {
        return -1;
      }

      return 0;
    });

    if (reversedArray.length > 0) {
      return reversedArray[0].id;
    }

    return 0;
  };
  
  const onDrop = (newTree: NodeModel<MenuItem>[], options:DropOptions) => {
    const {dropTargetId, monitor} = options;
    const itemType = monitor.getItemType();
    if(itemType === NativeTypes.TEXT) {
       const nodeJson = monitor.getItem().text;
       const data = JSON.parse(nodeJson);
       const idx = options.destinationIndex;
       if(Array.isArray(data)){
         const articles = data as ArticleResult[];
          const nodes = articles.map((article, index)=>{
            const id = getLastId(newTree) + index + 1;
            return {
              id: id,
              text: article.name,
              parent: dropTargetId,
              data: new ArticleMenuItem(MenuItem.emptyId, id,article.name, article.id, undefined, article,true)
            } as NodeModel<ArticleMenuItem>;
          });
          
          newTree.splice(options.destinationIndex, 0, ...nodes)
          setTree([...newTree]);
       } else {
         const article = data as ArticleResult;
         const id = getLastId(newTree) + 1;
         const node = {
           id: id,
           text: article.name,
           parent: dropTargetId,
           data: new ArticleMenuItem(MenuItem.emptyId, id,article.name, article.id, undefined, article, true)
         } as NodeModel<ArticleMenuItem>;
         
         newTree.splice(options.destinationIndex, 0, node)
         setTree([...newTree]);
       }
       
       emitItemsChanged();
      return;
    }
    
    setTree(newTree)
  };
  
  const emitItemsChanged = (aTree?: NodeModel<MenuItem>[])=>{
    const items = mapToDomainModel(aTree ?? tree);
    props.onItemsChanged && props.onItemsChanged(items);
  }
  
  const onSave = ()=>{
    const toSave = new Menu(menu!.id, menu!.name, mapToDomainModel(tree));
    props.onSave && props.onSave(toSave);
  }
  
  const OnDelete = ()=>{
    const toDelete = new Menu(menu!.id, menu!.name, mapToDomainModel(tree));
    props.onDelete && props.onDelete(toDelete);
  }
  const onDeleteNode = (id:number)=>{
    const newTree = tree.filter(x => x.id !== id);
    setTree(newTree);
    emitItemsChanged(newTree);
  }
  
  const onContainerAdd = (item: ContainerMenuItem)=>{
    const id = getLastId(tree) + 1;
    const node = {
      id:id,
      text: item.name,
      parent: 0,
      droppable: true,
      data: item
    } as NodeModel<ContainerMenuItem>;

    setTree([...tree, node]);
    setIsContainerModalOpen(false);
    emitItemsChanged();
  }
  
  const onContainerUpdate = (item: ContainerMenuItem)=>{
    const node = tree.find(x => x.id === item.itemId);
    if(node){
      node.data = item;
      setTree([...tree]);
    }
    setIsContainerModalOpen(false);
    setContainerEditItem(null);
    emitItemsChanged();
  }
  
  const onSeparatorAdd = (item: SeparatorMenuItem)=>{
    const id = getLastId(tree) + 1;
    const node = {
      id:id,
      text: item.name,
      parent: 0,
      droppable: false,
      data: item
    } as NodeModel<SeparatorMenuItem>;

    setTree([...tree, node]);
    setIsSeparatorModalOpen(false);
    emitItemsChanged();
  }

  const onSeparatorUpdate = (item: SeparatorMenuItem)=>{
    const node = tree.find(x => x.id === item.itemId);
    if(node){
      node.data = item;
      
      setTree([...tree]);
    }
    setIsSeparatorModalOpen(false);
    setSeparatorEditItem(null);
    emitItemsChanged();
  }
  
  const onExpandAll = ()=>{
    ref.current?.openAll();
  }
  const onCollapseAll = ()=>{
    ref.current?.closeAll();
  }

  return (
    <div className={"menu-builder"}>
      <div className={"menu-header"}>
        <h2>{t('pick-n-pay.menus.edit-menu.menu-builder.header')}</h2>
        <div className={"menu-tools"}>
          <div className={"btn-group"}>
            <button className={"btn"} onClick={()=>setIsContainerModalOpen(true)}>{t('pick-n-pay.menus.edit-menu.menu-builder.add-category.text')}</button>
            <button className={"btn"} onClick={()=>setIsSeparatorModalOpen(true)}>{t('pick-n-pay.menus.edit-menu.menu-builder.add-separator.text')}</button>
          </div>
        </div>
      
        <ContainerModal isOpen={isContainerModalOpen} locales={locales}
                        onClose={()=>{
                          setIsContainerModalOpen(false);
                          setContainerEditItem(null);
                        }}
                        item={containerEditItem}
                        onAdd={onContainerAdd}
                        onUpdate={onContainerUpdate}
        />

        <SeparatorModal isOpen={isSeparatorModalOpen} locales={locales}
                        item={separatorEditItem}
                        onClose={()=>{
                          setIsSeparatorModalOpen(false);
                          setSeparatorEditItem(null);
                        }}
                        onAdd={onSeparatorAdd}
                        onUpdate={onSeparatorUpdate}
        />
        
        
      </div>
      <div className={"menu-container"}>
        <div className={"btn-group"}>
          <button className={"btn"} onClick={onExpandAll}>{t('pick-n-pay.menus.edit-menu.menu-builder.expand.text')}</button>
          <button className={"btn"} onClick={onCollapseAll}>{t('pick-n-pay.menus.edit-menu.menu-builder.collapse.text')}</button>
        </div>
        <DndProvider backend={HTML5Backend}>
          <Tree tree={tree}
                ref={ref}
                rootId={0}
                onDrop={onDrop}
                extraAcceptTypes={[NativeTypes.TEXT]}
                rootProps={{role: 'list'}}
                classes={{
                  root: 'menu',
                  listItem: "list-item",
                  draggingSource: 'dragging-source',
                }}
                sort={false}
                insertDroppableFirst={false}
                dropTargetOffset={5}
                canDrop={(tree, {dragSource, dropTargetId, dropTarget}: DropOptions) => {
                  if (dragSource?.parent === dropTargetId) {
                    return true;
                  }
                }}
                render={(node: NodeModel<MenuItem>, {depth, isOpen, draggable, onToggle}: RenderParams) => (
                  <>
                    {node.data.type === MenuItemTypes.Container && (
                      <ContainerMenuItemComponent node={node} depth={depth} isOpen={isOpen} onToggle={onToggle} onDelete={onDeleteNode} onEdit={(nodeId)=>{
                        const node = tree.find(x => x.id === nodeId);
                        if(node){
                          setContainerEditItem(node.data as ContainerMenuItem);
                          setIsContainerModalOpen(true);
                        }
                      }}/>
                    )}
                    {node.data.type === MenuItemTypes.Article && (
                      <ArticleMenuItemComponent node={node} depth={depth} isOpen={isOpen} onToggle={onToggle} onDelete={onDeleteNode}/>
                    )}
                    {node.data.type === MenuItemTypes.Separator && (
                      <SeparatorMenuItemComponent node={node} depth={depth} isOpen={isOpen} onToggle={onToggle} onDelete={onDeleteNode} onEdit={(nodeId)=>{
                        const node = tree.find(x => x.id === nodeId);
                        if(node){
                          setSeparatorEditItem(node.data as SeparatorMenuItem);
                          setIsSeparatorModalOpen(true);
                        }
                      }}/>
                    )}
                  </>
                )}
                placeholderRender={(node: NodeModel<MenuItem>, {depth}: PlaceholderRenderParams) => (
                  <Placeholder depth={depth}/>
                )}
                dragPreviewRender={(monitorProps)=>(
                  <TestDragPreview monitorProps={monitorProps}/>
                )}
          />
        </DndProvider>
        <span className={"empty-text"}>{t('pick-n-pay.menus.edit-menu.menu-builder.empty.text')}</span>
        <div className={"menu-footer btn-group"}>
          <ButtonConfirm className={"btn btn-delete"} onConfirm={OnDelete} 
                         message={t('pick-n-pay.menus.edit-menu.menu-builder.delete.message')} 
                         confirmText={t('pick-n-pay.menus.edit-menu.menu-builder.delete.confirmText')}>
            {t('pick-n-pay.menus.edit-menu.menu-builder.delete.text')}
          </ButtonConfirm>
          <Link className={"btn btn-cancel"} to={'/pick-n-pay/menus'}>
            {t('pick-n-pay.menus.edit-menu.menu-builder.cancel.text')}
          </Link>
          <button className={"btn btn-save"} onClick={onSave}>
            {t('pick-n-pay.menus.edit-menu.menu-builder.save.text')}
          </button>           
        </div>
      </div>
    </div>
  )
}

const Placeholder = (props: { depth: number }) => {
  return <div className={"menu-item placeholder"}></div>;
};

const TestDragPreview = (props:any)=>{
  const item = props.monitorProps.item;
  return (
    <div className={"drag-preview"}>
      <span>{item.text}</span>
    </div>
  )
}

export default MenuBuilder;
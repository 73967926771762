import {ArticleResult, PaginatedResult, SubDepartment} from "../types";
import React, {ChangeEvent, useEffect, useState} from "react";
import Loading from "../../Loading";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import classNames from "classnames";
import {useTranslation} from "react-i18next";


interface State {
  query:string,
  pageIndex:number,
  categoryFilter:string,
}
interface Props {
  usedArticleIds?:string[]
}

const ArticleSearch  = (props:Props)=>{
  const [state, setState] = useState<State>({
    query: '',
    pageIndex: 0,
    categoryFilter: ''
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [results, setResults] = useState<ArticleResult[]>([]);
  const [categories, setCategories] = useState<SubDepartment[]>([]);
  const {targetRef, isIntersecting} = useIntersectionObserver({threshold: 0.5});
  const [selected, setSelected] = useState<ArticleResult[]>([]);
  const {t} = useTranslation();
  
  useEffect(()=>{
    if(!hasItems){
      return;
    }
    fetchData();
  },[isIntersecting]);
  
  useEffect(() => {
    setLoading(true);
    setResults([]);
    fetchData()
    
  }, [state.query, state.categoryFilter]);
  
  const hasItems = results.length > 0;

  const fetchData = ()=>{
    const qs = new URLSearchParams();
    qs.set('query', state.query);
    qs.set('pageIndex', state.pageIndex.toString());
    qs.set('pageSize', '20');
    if(state.categoryFilter){
      qs.set('subDepartment', state.categoryFilter);
    }
    
    const promises = [
      fetch(`api/pick-n-pay/articles?${qs.toString()}`)
        .then(r => r.json())
        .then(data => {
          return data as PaginatedResult<ArticleResult>;
        }),
      fetch('api/pick-n-pay/articles/subdepartments')
        .then(r => r.json())
        .then(data => {
          const result : SubDepartment[] = data;
          return result.sort((a,b)=>a.name.localeCompare(b.name));
        }),
    ];

    Promise.all(promises).then(([articles, categories])=>{
      setState((prev)=>({...prev, pageIndex: prev.pageIndex + 1}));
      setResults((prev)=>[...prev, ...articles.items]);
      setCategories(categories);
      setLoading(false);
    });
  }
  
  const filterChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    setState({...state, categoryFilter: e.target.value, pageIndex:0});
  }
  const queryChanged = (e:ChangeEvent<HTMLInputElement>)=>{
    setState({...state, query: e.target.value, pageIndex:0 });
  }
  
  const handleDragStart = (e:React.DragEvent<HTMLElement>, article:ArticleResult)=>{
    let json = JSON.stringify(article);
    const multiple = selected.length > 0;
    e.target.classList.add("dragging");
    if(multiple){
      json = JSON.stringify([...selected]);
      e.target.classList.add("multiple");
    }
    
    e.dataTransfer?.setData("text", json);
    setTimeout(()=>{
      e.target.classList.remove("dragging");
      if(multiple){
        e.target.classList.remove('multiple')
      }
    }, 1);
  }
  const handleDragEnd = (e:React.DragEvent<HTMLLIElement>, article:ArticleResult)=>{
    setSelected([]);
    e.target.classList.remove("dragging");
  }
  
  const onItemClick = (e:React.MouseEvent<HTMLElement>, article:ArticleResult)=>{
    if(e.shiftKey){
      if(selected.includes(article)){ 
        setSelected(selected.filter(a=>a !== article));
      } else {
        setSelected([...selected, article]);
      }
      
    }
    else {
      setSelected([]);
    }
  }
  
  const getClasses = (article:ArticleResult)=>{
    return classNames({
      "article":true,
      "selected": selected.includes(article),
      "used": isArticleUsed(article)
    });
  }
  
  const isArticleUsed = (article:ArticleResult)=>{
    return props.usedArticleIds?.includes(article.id) ?? false;
  }
  
  return (
    <div className={"article-search"}>
      <div className={"filter-container"}>
        <form className={"filters"}>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t('pick-n-pay.stickers.search')}</label>
            <input className={"form-control"} type={"search"} onChange={queryChanged}/>
          </div>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t('pick-n-pay.stickers.category.label')}</label>
            <select className={"form-select"} onChange={filterChanged}>
              <option value={""}>{t('pick-n-pay.stickers.category.all')}</option>
              {categories?.map(c => (
                <option key={c.code} value={c.code}>{c.name}</option>
              ))}
            </select>
          </div>
        </form>
      </div>

      {!hasItems && !loading && (
        <div className={"alert"}>
          <span>{t('pick-n-pay.article-search.no-results')}</span>
        </div>
      )}

      
      <div className={"shift-info"}>
        <p>{t('pick-n-pay.menus.edit-menu.article-search.multi-select.message')}</p>
        
        {selected.length > 0 && (
          <p className={"selected-items-counter"}>{t('pick-n-pay.menus.edit-menu.article-search.multi-select.count', { count: selected.length})}</p>
        )}
      </div>
      
      <div className={"result-container"}>
      
        <ol className={"result"}>
          {results.map(article => (
            <li  
                className={getClasses(article)} 
                key={article.id} 
                draggable 
                data-selected={selected.includes(article)}
                onClick={(e)=>onItemClick(e, article)}
                onDragStart={(e)=>handleDragStart(e,article)}
                data-selected-count={selected.length}
                onDragEnd={handleDragEnd}>
              <ArticleSearchResultItem article={article} />
            </li>
          ))}
        </ol>
        {loading && <Loading visible={true}/>}
        {hasItems && (
          <div className={"scroll-observer"} ref={targetRef}></div>
        )}
      </div>
    </div>
  )
}


const ArticleSearchResultItem = (props: {article: ArticleResult})=>{
  const [article, setArticle] = useState<ArticleResult>(props.article);
  const [imageError, setImageError] = useState<boolean>(false);
  const {t} = useTranslation();
  return (
    <>
      {imageError && (
        <div className={"image-error"}>
          {t('pick-n-pay.menus.edit-menu.article-search.no-image')}
        </div>)
      }

      {!imageError &&(
        <img src={`api/pick-n-pay/images/${article.id}`} alt={article.name} onError={()=>setImageError(true)}/>
      )}
      <h1>{article.name} <span className={"article-id"}>{article.id}</span></h1>
      <span className={"department"}>{article.subDepartmentName}</span>

    </>
  )
}


export default ArticleSearch;